import React, { CSSProperties } from 'react';
import { Component } from "react";
import { Row, Container, Col, Navbar, Nav, NavDropdown, Form, FormControl, Button, Card, ListGroupItem, ListGroup, Image, Carousel } from "react-bootstrap";
import spotifyLogo from '../images/Spotify_Icon_RGB_Black.png';
import youtubeLogo from '../images/youtube_social_icon_dark.png';
import facebookLogo from '../images/f_logo_RGB-Black_512.png';
import bwphoto from '../images/bwphoto.jpg';
import bwkids from '../images/bwkids.jpg';
import kids from '../images/kids.jpg';
import aeldre from '../images/aeldre.jpg';
import flygel from '../images/flygel.jpg';
import ReactPlayer from 'react-player'



export class Home extends Component<{}, {}> {



    render() {
        return <React.Fragment>
            <Container>
                <Row className="mt-5 mb-5 text-center">
                    <Col>
                        <h1>Julie Winckler</h1>
                    </Col>
                </Row>
                <Row className="text-center mt-5 mb-5">
                    <Col>
                        <a href="mailto:julie-winckler@hotmail.com" className="btn btn-secondary btn-lg">Skriv en email</a>
                    </Col>
                </Row>
                <Row className="mt-5 mb-4">
                    <Col xl className="mb-xl-0 mb-4">
                        <Row className="text-center">
                            <Col>
                                <h3>
                                    Musikken til lindring
                                </h3>
                            </Col>
                        </Row>
                        <Row style={{textAlign: "justify"}}>
                            <Col>Julie Winckler spiller intimkoncerter for mennesker, som har brug for musikken til lindring. Med en baggrund som musikterapeut, sangskriver og sanger komponerer Julie sange, som er designet til at skabe ro, eftertænksomhed, nærvær og lindring. Julie bruger bevidst stemmen og musikkens komponenter til at skabe et rum, hvor roen kan sænke sig.</Col>
                        </Row>
                    </Col>
                    <Col xl className="d-xl-block d-none ml-5">
                        <Image src={bwphoto} fluid />
                    </Col>
                    <Col xl className="d-xl-none d-block">
                        <Image src={bwphoto} fluid />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xl className="d-xl-block d-none mr-5">
                        <ReactPlayer url='/ildigilden.mp4' playing controls className="w-100 h-100" />
                    </Col>
                    <Col xl className="d-xl-block d-none">
                        <Row className="text-center">
                            <Col>
                                <h3>
                                    DET KAN I FORVENTE
                                </h3>
                            </Col>
                        </Row>
                        <Row style={{textAlign: "justify"}}>
                            <Col>I kan forvente en intimkoncert, hvor Julie er i afslappet øjenhøjde med lytterne. Koncerten består af originale sange, som er skrevet til at regulere arousal ned. Den rolige atmosfære giver mulighed for at være til stede i nuet og til mærke efter med sjæl og krop. Deltagerne inviteres også til fællessang som del af koncerten. Det giver en særlig stemning af fællesskab og nærvær.</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-4 d-xl-none d-block ">
                    <Col xl className="mb-xl-0 mb-4">
                        <Row className="text-center">
                            <Col>
                                <h3>
                                    DET KAN I FORVENTE
                                </h3>
                            </Col>
                        </Row>
                        <Row style={{textAlign: "justify"}}>
                            <Col>I kan forvente en intimkoncert, hvor Julie er i afslappet øjenhøjde med lytterne. Koncerten består af originale sange, som er skrevet til at regulere arousal ned. Den rolige atmosfære giver mulighed for at være til stede i nuet og til mærke efter med sjæl og krop. Deltagerne inviteres også til fællessang som del af koncerten. Det giver en særlig stemning af fællesskab og nærvær.</Col>
                        </Row>
                    </Col>
                    <Col xl>
                        <ReactPlayer url='/ildigilden.mp4' playing controls className="w-100 h-100" />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xl className="mb-xl-0 mb-4">
                        <Row className="text-center">
                            <Col>
                                <h3>
                                    HVEM SPILLER JULIE FOR
                                </h3>
                            </Col>
                        </Row>
                        <Row style={{textAlign: "justify"}}>
                            <Col>Julies musik henvender sig til børn, unge, voksne og ældre, og hver koncert sammensættes ud fra deltagernes behov. Hospitaler, hospices, plejecentre, plejehjem, skoler, børn med specielle behov, mennesker med funktionsnedsættelse, demensafsnit, mennesker med psykiske udfordringer eller mennesker uden specielle udfordringer, men som kan have brug for en afstressende pause i hverdagen, er eksempler på steder og mennesker, som kunne have gavn af musikken til lindring.</Col>
                        </Row>
                    </Col>
                    <Col xl className="d-xl-block d-none ml-5">
                        <Carousel>
                            <Carousel.Item style={{height:"344px", overflow: "hidden"}}>
                                <Image
                                    className="w-100"
                                    src={bwkids}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item style={{height:"344px", overflow: "hidden"}}>
                                <Image
                                    className="w-100"
                                    src={aeldre}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item style={{height:"344px", overflow: "hidden"}}>
                                <Image
                                    className="w-100"
                                    src={kids}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item style={{height:"344px", overflow: "hidden"}}>
                                <Image
                                    className="w-100"
                                    src={flygel}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col xl className="d-xl-none d-block">
                        <Carousel>
                            <Carousel.Item >
                                <Image
                                    className="w-100 mh"
                                    src={bwkids}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item >
                                <Image
                                    className="w-100 mh"
                                    src={aeldre}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <Image
                                    className="w-100 mh"
                                    src={kids}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item >
                                <Image
                                    className="w-100 mh"
                                    src={flygel}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
                <Row className="text-center mt-5 mb-5">
                    <Col>
                        <a href="mailto:julie-winckler@hotmail.com" className="btn btn-secondary btn-lg">Skriv en email</a>
                    </Col>
                </Row>
                <Row className="text-center mt-5 mb-5">
                    <Col>
                        <a href="/pressemateriale.pdf" target="_blank" className="btn btn-secondary btn-lg">Download pressemateriale</a>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xl="4" className="mt-5 mb-5">
                        <Card style={{ cursor: 'pointer' }} onClick={() => {
                            window.open("https://www.facebook.com/JulieWincklerBand/")
                        }}>
                            <Card.Img className="mx-auto mt-4 mb-4" style={{ height: "5rem", width: "5rem" }} variant="top" src={facebookLogo} />
                            <Card.Body>
                                <Card.Title>Følg Julie på facebook</Card.Title>
                                <Card.Text>
                                    Kig med når Julie offentliggøre nyheder, koncerter og musik på facebook.
                            </Card.Text>
                                <div className="fb-page" data-href="https://www.facebook.com/JulieWincklerBand/" data-tabs="timeline" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/JulieWincklerBand/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/JulieWincklerBand/">Julie Winckler</a></blockquote></div>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col xl="4" className="mt-5 mb-5">
                        <Card style={{ cursor: 'pointer' }} onClick={() => {
                            window.open("https://open.spotify.com/album/1t5Sc1F2lOuoi6LxdXjP3l?si=LBvFWHAIQTCjtNLiX6DiAQ")
                        }}>
                            <Card.Img className="mx-auto mt-4 mb-4" style={{ height: "5rem", width: "5rem" }} variant="top" src={spotifyLogo} />
                            <Card.Body>
                                <Card.Title>Lyt på Spotify</Card.Title>
                                <Card.Text>
                                    Tryk her for at gå til albummet på Spotify
                            </Card.Text>
                                <iframe src="https://open.spotify.com/embed/album/1t5Sc1F2lOuoi6LxdXjP3l" style={{ width: "100%" }} height="380" frameBorder="0" allowTransparency={true} allow="encrypted-media"></iframe>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="4" className="mt-5 mb-5">
                        <Card style={{ cursor: 'pointer' }} onClick={() => {
                            window.open("https://www.youtube.com/playlist?list=OLAK5uy_lVOTHlClez5s_mtkNYxkflFLRuxlOUnJQ")
                        }}>
                            <Card.Img className="mx-auto mt-4 mb-4" style={{ height: "5rem", width: "auto" }} variant="top" src={youtubeLogo} />
                            <Card.Body>
                                <Card.Title>Lyt på Youtube</Card.Title>
                                <Card.Text>
                                    Tryk her for at gå til albummet på Youtube
                            </Card.Text>
                                <iframe style={{ width: "100%" }} height="315" src="https://www.youtube.com/embed/WNqrLqNBmnE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="text-center mt-5 mb-5">
                    <Col>
                        Ophavsret © 2019 Julie Winckler
                </Col>
                </Row>
            </Container>
        </React.Fragment>

    }
}