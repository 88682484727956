import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Home } from './components/Home';
import { Navbar, Nav, NavDropdown, FormControl, Button, Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/FormGroup';
import flowerPng from '../src/images/blomst.png'

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Route path="*" component={Home}></Route>
    </Router>
  );
}

export default AppRouter;
